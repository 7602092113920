import React from "react";
import { Link } from "react-router-dom";
import { AnneshaFullImg } from "../images";

const PrincipalIncharge = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Principal Incharge</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Principal Incharge</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="features-area Principal_sec">
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-4 col-md-12">
              <div className="features-img text-center">
                <img src={AnneshaFullImg} alt="" />
                <h4>Mrs Annesha A. Acharyya</h4>
                <p>(Principal Incharge)</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="features-content">
                <p>
                  <b>Dear students,</b>
                  <br /> Pharmacy is not just a career—it is a calling, a
                  commitment to serve, innovate, and make a difference in the
                  lives of countless individuals. The field of pharmacy has
                  evolved tremendously over the years. This transformation
                  reflects the critical role that pharmacy professionals play in
                  shaping the future of healthcare. As pharmacy students, you
                  are entrusted with the responsibility of acquiring scientific
                  knowledge and practical skills that will serve society in the
                  most meaningful ways.
                </p>
                <p>
                  I encourage you to remain curious, ask questions, and seek
                  answers beyond textbooks. Knowledge is a dynamic entity, and
                  staying ahead requires persistent effort and dedication.
                </p>
                <p>
                  There will be times when the journey feels challenging—exams
                  may be tough, concepts may seem complex, and practical
                  sessions may feel overwhelming. But remember, every struggle
                  is a step towards greatness. The effort you put in today will
                  shape your future and define your professional journey.
                </p>
                <p>
                  As we move forward together, let us uphold the values of
                  knowledge, integrity, and service. The future is bright, and
                  the possibilities are endless. With passion, perseverance, and
                  dedication, we can achieve great things.
                </p>
              </div>
            </div>

            <div className="col-12 features-content">
              <p>
                Let us continue to strive for excellence, embrace challenges,
                and work towards making a meaningful impact in the world of
                pharmacy and healthcare.
              </p>
              <p>
                Wishing you all success, happiness, and fulfillment in your
                academic and professional endeavors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrincipalIncharge;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StudentChart from "../components/StudentChart";
import { getApiCall } from "../Service/Home";
import { PDFImages } from "../images";
import { endpoints } from "../Config/endpoints";

const StudentData = () => {
  const [holdStudentsData, setHoldStudentsData] = useState([]);
  const getStudentsData = async () => {
    const res = await getApiCall(endpoints.getStudents);
    setHoldStudentsData(res?.data?.data);
  };
  useEffect(() => {
    getStudentsData();
  }, []);
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Student Data</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Student Data</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="infrastructure-content">
                <h3 className="text-center">Student Enrollment Statistics</h3>
              </div>
            </div>
            <div className="col-sm-6">
              <StudentChart />
            </div>
          </div>
          <div className="row mt-5">
            <div className="infrastructure-content">
              <h3 className="text-center">Student List</h3>
            </div>
          </div>
          <div className="row mt-3">
            {holdStudentsData &&
              holdStudentsData?.sort((a, b) => b.sessionYear - a.sessionYear) &&
              holdStudentsData.map((item, i) => {
                return (
                  <div key={i} className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <a
                      href={item?.file.map((e) => e?.url)}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <div className="services-box">
                        <div className="services-inner">
                          <div className="icon">
                            <img src={PDFImages} alt="PDF logo" />
                          </div>
                          <div className="text">
                            <h5>{item?.sessionYear}</h5>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentData;

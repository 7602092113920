import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import {
  Partner1,
  Partner2,
  Partner3,
  Partner4,
  Partner5,
  Partner6,
  Partner7,
  Partner8,
  Partner9,
  Partner10,
  Partner11,
  Partner12,
  Partner13,
  Partner14,
  Partner15,
  Partner16,
  Partner17,
  Partner18,
  Partner19,
  Partner20,
  Partner21,
  Partner22,
  Partner23,
} from "../images";

const TrainingPlacement = () => {
  const logos = [
    Partner1,
    Partner2,
    Partner3,
    Partner4,
    Partner5,
    Partner6,
    Partner7,
    Partner8,
    Partner9,
    Partner10,
    Partner11,
    Partner12,
    Partner13,
    Partner14,
    Partner15,
    Partner16,
    Partner17,
    Partner18,
    Partner19,
    Partner20,
    Partner21,
    Partner22,
    Partner23,
  ];
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Training & Placement</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Training & Placement</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 logo-slider">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={20}
                autoplay
                autoplayTimeout={2000}
                smartSpeed={1000}
                responsive={{
                  0: { items: 2 },
                  600: { items: 3 },
                  1000: { items: 5 },
                }}
              >
                {logos.map((logo, index) => (
                  <div key={index} className="item">
                    <img
                      src={logo}
                      alt={`Logo ${index + 1}`}
                      className="img-fluid"
                      style={{ maxHeight: "100px" }}
                    />
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingPlacement;

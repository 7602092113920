import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApiCall } from "../Service/Home";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { endpoints } from "../Config/endpoints";

const Gallery = () => {
  const [holdGalleryData, setHoldGalleryData] = useState([]);
  const getGallery = async () => {
    const res = await getApiCall(endpoints.gallerySectionGet);
    setHoldGalleryData(res?.data?.data?.files);
  };
  useEffect(() => {
    getGallery();
  }, []);
  useEffect(() => {
    Fancybox.bind("[data-fancybox='gallery']", {
      Thumbs: {
        autoStart: true,
      },
    });

    return () => {
      Fancybox.unbind("[data-fancybox='gallery']");
      Fancybox.close();
    };
  }, []);

  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Gallery</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Gallery</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-bg">
        <div className="container">
          <div className="row">
            {holdGalleryData &&
              holdGalleryData.map((item, i) => (
                <div key={i} className="col-sm-3">
                  <div className="main-gallery">
                    <a href={item?.url} data-fancybox="gallery">
                      <img src={item?.url} alt="" style={{ width: "100%" }} />
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;

import React from "react";
import { Link } from "react-router-dom";
import { PresidentFullImg } from "../images";

const PresidentDesk = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>President Desk</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">President Desk</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="features-area Principal_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="features-img text-center">
                <img src={PresidentFullImg} alt="" />
                <h4>Guruprosad Mukherjee</h4>
                <p>(President)</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="features-content">
                <p>
                  <b>
                    Dear Students, Faculty, and Esteemed Members of the Bengal
                    Education of Pharmacy College Community,
                  </b>
                  <br />
                  It is with great pride and enthusiasm that I welcome you all
                  to Bengal Education of Pharmacy College. As the President of
                  this esteemed institution, I am honored to be a part of a
                  journey that fosters not only academic brilliance but also the
                  development of well-rounded, responsible, and innovative
                  professionals in the field of pharmacy.
                </p>
                <p>
                  Our vision at Bengal Education of Pharmacy College is to
                  provide world-class education that bridges theory and
                  practice, creating professionals who are equipped to address
                  the healthcare needs of the future. We strive to instill a
                  deep understanding of pharmaceutical sciences, ethical values,
                  and critical thinking in our students. Our commitment to
                  nurturing future leaders in the field is reflected in our
                  excellent faculty, cutting- edge facilities, and our dynamic
                  academic programs that align with global standards.
                </p>
              </div>
            </div>
            <div className="col-12 features-content">
              <p>
                We are firm believers in the power of education to transform
                lives and society, and we take pride in offering an environment
                that encourages intellectual curiosity, personal growth, and
                collaborative learning. As you take your steps into the world of
                pharmacy, remember that the knowledge and skills you gain here
                will not only help you achieve personal success but also
                contribute to the betterment of global health.
              </p>
              <p>
                I urge you all to embrace the challenges, seize opportunities
                for growth, and work with passion and dedication. Together, we
                can continue to build a legacy of excellence and impact the
                healthcare industry in meaningful ways.
              </p>
              <p>
                Thank you for being part of Bengal Education of Pharmacy
                College, and I wish you all the best in your academic and
                professional endeavors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PresidentDesk;

import React from "react";
import { SecretaryPic } from "../images";
import { Link } from "react-router-dom";

const Secretary = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Secretary Desk</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Secretary Desk</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="features-area Principal_sec">
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-3 col-md-12">
              <div className="features-img text-center">
                <img src={SecretaryPic} alt="" />
                <h4>Mr. Soumyajit Mukherjee</h4>
                <p>(Secretary)</p>
              </div>
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="features-content">
                <p>
                  <b>Dear Students, Faculty, and Esteemed Stakeholders,</b>
                  <br />
                  It is with immense pleasure that I extend my warm greetings to
                  you on behalf of Bengal Education of Pharmacy College. As we
                  continue to nurture and guide the leaders of tomorrow, our
                  institution remains steadfast in providing a dynamic and
                  intellectually stimulating environment for our students.
                </p>
                <p>
                  At Bengal Education of Pharmacy College, we are committed to
                  delivering high-quality education, fostering innovation, and
                  encouraging the holistic development of every individual. Our
                  dedicated faculty members, state-of-the-art facilities, and
                  strong industry connections ensure that our students receive
                  an education that is both relevant and impactful.
                </p>
                <p>
                  We believe that education is not just about academic
                  excellence but also about shaping responsible and skilled
                  professionals who will contribute positively to the healthcare
                  industry. Our goal is to equip our students with the
                  knowledge, practical skills, and ethical foundation necessary
                  to excel in the ever-evolving field of pharmacy.
                </p>
                <p>
                  As we move forward, we remain focused on continuous
                  improvement and creating opportunities for growth and success.
                  I encourage you all to embrace the journey ahead, stay
                  curious, and strive to make the most of the educational
                  experiences offered at our esteemed institution.
                </p>
                <p>
                  Together, we will continue to reach new heights in the world
                  of pharmacy education and beyond.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Secretary;

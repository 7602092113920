import React from "react";
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import "./Header.css";
import { CallIcon, EmailIcon, Logo } from "../../images";

const Header = () => {
  return (
    <>
      <div className="top_header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="top-btn d-flex justify-content-center">
                <Link to="https://account.vedmarg.com/bengal-education-of-pharmacy-949/admission/form">
                  Admission form
                </Link>
                <Link to="https://account.vedmarg.com/login">User login</Link>
                <Link
                  className="btn-pulse"
                  to="https://account.vedmarg.com/login"
                  target="_blank"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Pay Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle">
              <RxCross1 />
            </span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>
      <div className="site-navbar-wrap">
        <div className="header-top">
          <div className="side-gap">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 ">
                  <div className="header-cta">
                    <h5 className="mb-0">Bengal Education of Pharmacy</h5>
                    <p className="mb-0">RECOGNISED BY PCI GOVT. OF INDIA</p>
                    <p className="mb-0">
                      AFFILIATED TO 'WBSCTVESD' GOVT. OF W.B
                    </p>
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 text-right">
                  <div className="header-cta">
                    <ul className="d-flex align-items-center justify-content-between">
                      <li>
                        <div className="top-btn d-flex">
                          <Link to="https://account.vedmarg.com/bengal-education-of-pharmacy-949/admission/form">
                            Apply Now
                          </Link>
                          <Link to="https://account.vedmarg.com/login">
                            User login
                          </Link>
                          <Link
                            className="btn-pulse"
                            to="https://account.vedmarg.com/login"
                            target="_blank"
                          >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Pay Now
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="call-box">
                          <div className="icon">
                            <img src={CallIcon} alt="" />
                          </div>
                          <div className="text">
                            <span>For Admission</span>
                            <strong>
                              <Link to="tel:+91 7601932023">
                                +91 7601932023
                              </Link>
                              <div
                                style={{
                                  display: "inline-block",
                                  margin: "0px 5px",
                                }}
                              >
                                /
                              </div>
                              <Link to="tel:+91 8906861061">
                                +91 8906861061
                              </Link>
                            </strong>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="call-box">
                          <div className="icon">
                            <img src={EmailIcon} alt="" />
                          </div>
                          <div className="text">
                            <span>Email Now</span>
                            <strong>
                              <Link to="to:info.bengaleducationofpharmacy.org">
                                info@bengaleducationofpharmacy.org
                              </Link>
                            </strong>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-navbar site-navbar-target js-sticky-header">
          <div className="side-gap">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-2 col-md-4 col-sm-9 col-8 main-logo">
                  <div className="d-flex align-items-center">
                    <div>
                      <Link to="/">
                        <img src={Logo} alt="logo" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-md-8 col-sm-3 col-4">
                  <nav className="site-navigation text-right" role="navigation">
                    <div className="container">
                      <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                        <Link
                          to="/"
                          className="site-menu-toggle js-menu-toggle text-white"
                        >
                          <span className="icon-menu h3">
                            <FaBars />
                          </span>
                        </Link>
                      </div>
                      <ul className="site-menu main-menu js-clone-nav d-none d-lg-block">
                        <li>
                          <Link to="/" className="nav-link">
                            Home
                          </Link>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            About Us
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/governing-body" className="nav-link">
                                Governing Body
                              </Link>
                            </li>
                            <li>
                              <Link to="/vision-mission" className="nav-link">
                                Vision & Mission
                              </Link>
                            </li>
                            <li>
                              <Link to="/president-desk" className="nav-link">
                                President Desk
                              </Link>
                            </li>
                            <li>
                              <Link to="/secretary-desk" className="nav-link">
                                Secretary Desk
                              </Link>
                            </li>
                            <li>
                              <Link to="/principal-desk" className="nav-link">
                                Principal Desk
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/principal-incharge"
                                className="nav-link"
                              >
                                Principal Incharge
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Admission
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link
                                to="https://account.vedmarg.com/bengal-education-of-pharmacy-949/admission/form"
                                className="nav-link"
                              >
                                Online Admission
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/prospectus-structure"
                                className="nav-link"
                              >
                                Prospectus & Fees Structure
                              </Link>
                            </li>
                            <li>
                              <Link to="/courses-offered" className="nav-link">
                                Courses Offered
                              </Link>
                            </li>
                            <li>
                              <Link to="/eligibility" className="nav-link">
                                Eligibility
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Certificates
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/approval-of-pci" className="nav-link">
                                Approval of PCI
                              </Link>
                            </li>
                            <li>
                              <Link to="/wbsct-&-ve-&-sd" className="nav-link">
                                WBSCTVESD NOC
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/d-pharam-constant-of-affiliation"
                                className="nav-link"
                              >
                                WBSCTVESD Certificate
                              </Link>
                            </li>
                            <li>
                              <Link to="/iso-certificate" className="nav-link">
                                ISO Certificate
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/udyam-certificate"
                                className="nav-link"
                              >
                                Udyam Certificate
                              </Link>
                            </li>
                            <li>
                              <Link to="/aishe" className="nav-link">
                                AISHE
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/BOSSE-accredition-certificate"
                                className="nav-link"
                              >
                                BOSSE Accreditation Certificate
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Academics
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/faculties" className="nav-link">
                                Faculties
                              </Link>
                            </li>
                            <li>
                              <Link to="/student-data" className="nav-link">
                                Student Data
                              </Link>
                            </li>
                            <li>
                              <Link to="/holiday-list" className="nav-link">
                                Academic Calender / Holiday List
                              </Link>
                            </li>
                            <li>
                              <Link to="/department" className="nav-link">
                                Department
                              </Link>
                            </li>
                            <li>
                              <Link to="/syllabus" className="nav-link">
                                Syllabus
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Infrastructure
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/classroom" className="nav-link">
                                Classroom
                              </Link>
                            </li>
                            <li>
                              <Link to="/library" className="nav-link">
                                Library
                              </Link>
                            </li>
                            <li>
                              <Link to="/laboratory" className="nav-link">
                                Laboratory
                              </Link>
                            </li>
                            <li>
                              <Link to="/gallery" className="nav-link">
                                Gallery
                              </Link>
                            </li>
                            <li>
                              <Link to="/seminar-hall" className="nav-link">
                                Seminar Hall
                              </Link>
                            </li>
                            <li>
                              <Link to="/wifi-campus" className="nav-link">
                                Wifi Campus
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Special Cells & Committee
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link
                                to="/training-placement"
                                className="nav-link"
                              >
                                Training & Placement
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/antiaging-committee-and-squad"
                                className="nav-link"
                              >
                                Anti Ragging Committee And Squad
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/gender-sensitization-cell"
                                className="nav-link"
                              >
                                Gender Sensitization Cell
                              </Link>
                            </li>
                            <li>
                              <Link to="/sc-st-cell" className="nav-link">
                                SC/ST CELL
                              </Link>
                            </li>
                            <li>
                              <Link to="/guidance-cell" className="nav-link">
                                Guidance Cell
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/internal-complaint"
                                className="nav-link"
                              >
                                Internal Complaint
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/contact-us" className="nav-link">
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApiCall } from "../Service/Home";
import { endpoints } from "../Config/endpoints";
import PdfLoader from "../components/Pdf/PdfLoader";

const HolidayList = () => {
  const [holdHolidaysData, setHoldHolidaysData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);

  const getAllHolidays = async () => {
    try {
      const res = await getApiCall(endpoints.getHoliday);
      console.log(res);

      if (res?.data?.data?.length > 0) {
        const sortedData = res.data.data.sort((a, b) => b.year - a.year);
        setHoldHolidaysData(sortedData);
        setSelectedYear(sortedData[0].year);
        setSelectedPdfUrl(sortedData[0].file?.url);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllHolidays();
  }, []);

  // Extract unique years from the data
  const uniqueYears = [
    ...new Set(holdHolidaysData.map((item) => item.year)),
  ].sort((a, b) => b - a);

  // Handle year selection
  const handleYearSelection = (year) => {
    setSelectedYear(year);
    const selectedPdf = holdHolidaysData.find((item) => item.year === year);
    setSelectedPdfUrl(selectedPdf?.file?.url || null);
  };
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Academic Calender / Holiday List</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  Academic Calender / Holiday List
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* Year Buttons */}
      <div className="container text-center mt-5">
        {uniqueYears.map((year) => (
          <button
            key={year}
            className={`btn btn-primary m-2 ${
              selectedYear === year ? "active" : ""
            }`}
            onClick={() => handleYearSelection(year)}
          >
            {year}
          </button>
        ))}
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              {selectedPdfUrl ? (
                <PdfLoader pdfUrl={selectedPdfUrl} />
              ) : (
                <p className="text-center mt-3">
                  No PDF available for {selectedYear}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HolidayList;

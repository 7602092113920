import React, { useState } from "react";
import { Link } from "react-router-dom";

const Calendar = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDaysInMonth = (month, year) =>
    new Date(year, month + 1, 0).getDate();
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Calendar</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Calendar</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-bg">
        <div className="container mt-4">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <button
              className="btn btn-secondary"
              onClick={() => setYear(year - 1)}
            >
              ← {year - 1}
            </button>
            <h2 className="fw-bold">{year}</h2>
            <button
              className="btn btn-secondary"
              onClick={() => setYear(year + 1)}
            >
              {year + 1} →
            </button>
          </div>

          <div className="row">
            {months.map((month, index) => (
              <div key={index} className="col-md-4 col-lg-3 mb-3">
                <div className="card" style={{ height: "100%" }}>
                  <div className="card-header text-center fw-bold">{month}</div>
                  <div className="card-body p-2">
                    <div className="d-flex justify-content-around fw-bold text-muted small">
                      {["S", "M", "T", "W", "T", "F", "S"].map((d, i) => (
                        <div key={i}>{d}</div>
                      ))}
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      {Array.from(
                        { length: new Date(year, index, 1).getDay() },
                        (_, i) => (
                          <div
                            key={`empty-${i}`}
                            className="text-center"
                            style={{ width: "14.2%" }}
                          ></div>
                        )
                      )}
                      {Array.from(
                        { length: getDaysInMonth(index, year) },
                        (_, day) => (
                          <div
                            key={day}
                            className="text-center p-1"
                            style={{ width: "14.2%" }}
                          >
                            {day + 1}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;

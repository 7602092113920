import React from "react";
import { Link } from "react-router-dom";
import { FreeWifi } from "../images";

const WifiCampus = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Wifi Campus</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Wifi Campus</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <img
                src={FreeWifi}
                alt="free-wifi"
                style={{ width: "180px", display: "table", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WifiCampus;

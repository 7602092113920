import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApiCall } from "../Service/Home";
import { endpoints } from "../Config/endpoints";
import PdfLoader from "../components/Pdf/PdfLoader";

const ISOCertificate = () => {
  const [holdISOCertificatesData, setHoldISOCertificatesData] = useState([]);
  const getISOCertificatesData = async () => {
    const res = await getApiCall(endpoints.getISOCertificate);
    console.log(res);

    setHoldISOCertificatesData(res?.data?.data);
  };
  useEffect(() => {
    getISOCertificatesData();
  }, []);
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>ISO Certificate</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">ISO Certificate</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              {holdISOCertificatesData &&
                holdISOCertificatesData.map((item, i) => {
                  return <PdfLoader key={i} pdfUrl={item?.file?.url} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ISOCertificate;

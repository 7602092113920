import React, { useEffect, useState } from "react";
import PdfLoader from "../components/Pdf/PdfLoader";
import { Link } from "react-router-dom";
import { getApiCall } from "../Service/Home";
import { endpoints } from "../Config/endpoints";

const AISHA = () => {
  const [holdAISHACertificatesData, setHoldAISHACertificatesData] = useState(
    []
  );
  const getAISHACertificatesData = async () => {
    const res = await getApiCall(endpoints.getAISHACertificate);
    setHoldAISHACertificatesData(res?.data?.data);
  };
  useEffect(() => {
    getAISHACertificatesData();
  }, []);
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>AISHE</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">AISHE</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              {holdAISHACertificatesData &&
                holdAISHACertificatesData.map((item, i) => {
                  return <PdfLoader key={i} pdfUrl={item?.file?.url} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AISHA;

import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getApiCall } from "../Service/Home";
import { endpoints } from "../Config/endpoints";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const StudentChart = () => {
  const [holdGraphsData, setHoldGraphsData] = useState([]);

  const getAllGraphs = async () => {
    try {
      const res = await getApiCall(endpoints.getGraphs);
      setHoldGraphsData(res?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAllGraphs();
  }, []);
  const data = {
    labels: holdGraphsData.map((e) => e?.year),
    datasets: [
      {
        label: "Student Count",
        data: holdGraphsData.map((e) => e?.counts), // Change values as needed
        backgroundColor: "#125875",
        borderColor: "#00001a",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: true, position: "top" },
      title: { display: true, text: "Year-wise Student Count" },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default StudentChart;

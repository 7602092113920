import React from "react";
import { Link } from "react-router-dom";

const AutoPopupModal = ({ show, setShow, modalData }) => {
  return (
    <>
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              onClick={() => setShow(false)}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="modal-body p-0">
              <img src={modalData[0]?.image?.url} alt="Modal Content" />
            </div>
            <div className="modal-footer">
              <Link
                to="https://account.vedmarg.com/bengal-education-of-pharmacy-949/admission/form"
                className="btn-pulse enroll-btn"
              >
                Enroll Now
              </Link>
              <div className="d-flex w-100 justify-content-between">
                <div className="d-flex flex-column">
                  <span>Call us</span>
                  <Link to="tel:+917601932023">+91 7601932023</Link>
                </div>
                <div className="d-flex flex-column text-right">
                  <span>Visit Our Website</span>
                  <Link to="https://www.bengaleducationofpharmacy.org/">
                    www.bengaleducationofpharmacy.org
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoPopupModal;

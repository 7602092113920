import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApiCall } from "../Service/Home";
import { endpoints } from "../Config/endpoints";

const Faculties = () => {
  const [holdFacultiesData, setHoldFacultiesData] = useState([]);

  const getAllFaculties = async () => {
    const res = await getApiCall(endpoints.getFaculty);
    console.log(res.data.data);

    setHoldFacultiesData(res?.data?.data);
  };
  useEffect(() => {
    getAllFaculties();
  }, []);
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Faculties</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Faculties</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row">
            {holdFacultiesData &&
              holdFacultiesData.map((item, i) => {
                return (
                  <div key={i} className="col-lg-4 col-md-6 col-sm-12">
                    <div className="main-faculties">
                      <div className="faculties-img">
                        <img src={item?.image?.url} alt="" />
                      </div>
                      <div className="faculties-content">
                        <div className="faculties-div">
                          <div className="faculties-left">
                            <p className="mb-0">Name</p>
                          </div>
                          <div className="faculties-right">
                            <p className="mb-0">: {item?.name}</p>
                          </div>
                        </div>
                        <div className="faculties-div">
                          <div className="faculties-left">
                            <p className="mb-0">Designation</p>
                          </div>
                          <div className="faculties-right">
                            <p className="mb-0">: {item?.designation}</p>
                          </div>
                        </div>
                        <div className="faculties-div">
                          <div className="faculties-left">
                            <p className="mb-0">Qualification</p>
                          </div>
                          <div className="faculties-right">
                            <p className="mb-0">: {item?.qualification}</p>
                          </div>
                        </div>
                        <div className="faculties-div">
                          <div className="faculties-left">
                            <p className="mb-0">Experience</p>
                          </div>
                          <div className="faculties-right">
                            <p className="mb-0">: {item?.experience} Years</p>
                          </div>
                        </div>
                        <div className="faculties-div">
                          <div className="faculties-left">
                            <p className="mb-0">Department</p>
                          </div>
                          <div className="faculties-right">
                            <p className="mb-0">: {item?.department}</p>
                          </div>
                        </div>
                        <div className="faculties-div">
                          <div className="faculties-left">
                            <p className="mb-0">Mobile No</p>
                          </div>
                          <div className="faculties-right">
                            <p className="mb-0">: {item?.mobile}</p>
                          </div>
                        </div>
                        <div className="faculties-div">
                          <div className="faculties-left">
                            <p className="mb-0">E-mail</p>
                          </div>
                          <div className="faculties-right">
                            <p className="mb-0">: {item?.email}</p>
                          </div>
                        </div>
                        <div className="faculties-div">
                          <div className="faculties-left">
                            <p className="mb-0">Status</p>
                          </div>
                          <div className="faculties-right">
                            <p className="mb-0">
                              :{" "}
                              {item?.active === true ? (
                                <span
                                  style={{
                                    backgroundColor: "green",
                                    color: "#fff",
                                    padding: "0px 5px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  Active
                                </span>
                              ) : (
                                <span
                                  style={{
                                    backgroundColor: "red",
                                    color: "#fff",
                                    padding: "0px 5px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  In Active
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faculties;

import apiClient from "../../Config/apiConfig";

const postApiCall = async (endpoint, body) => {
  try {
    const res = await apiClient.post(endpoint, body);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
const getApiCall = async (endpoint) => {
  try {
    const res = await apiClient.get(endpoint);
    return res;
  } catch (error) {
    console.log("error");
  }
};

export { postApiCall, getApiCall };

import React from "react";
import "./Slider.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { SliderFive, SliderOne } from "../../images";

const Slider = () => {
  return (
    <>
      <Carousel fade className="banner" interval={3000}>
        <Carousel.Item>
          <img className="w-100" src={SliderOne} alt="" />
          <Carousel.Caption>
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content">
                    <h5 className="animate fadeInUp one">
                      Bengal Education of Pharmacy
                    </h5>
                    <h2 className="animate fadeInUp one">
                      Education is the best key success in life
                    </h2>
                    <p className="animate fadeInUp two">
                      Bengal Education of Pharmacy is a unique exemplary
                      educational institute for pharmacy,for the purpose of
                      providing standard education and positivity in the field
                      of pharmacy.
                    </p>
                    <div className="slider-btn animate fadeInUp three">
                      <Link
                        className="btn"
                        to="/overview"
                        target="_blank"
                        style={{ marginRight: "10px" }}
                      >
                        Read More
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5"></div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="w-100" src={SliderFive} alt="" />
          <Carousel.Caption>
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content">
                    <h5 className="animate fadeInUp one">WelCome to BEOP</h5>
                    <h2 className="animate fadeInUp one">
                      Education is the best key success in life
                    </h2>
                    <p className="animate fadeInUp two">
                      Bengal Education of Pharmacy is a unique exemplary
                      educational institute for pharmacy,for the purpose of
                      providing standard education and positivity in the field
                      of pharmacy.
                    </p>
                    <div className="slider-btn animate fadeInUp three">
                      <Link
                        className="btn"
                        to="/overview"
                        target="_blank"
                        style={{ marginRight: "10px" }}
                      >
                        Read More
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5"></div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Slider;

import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import PrincipalDesk from "./pages/PrincipalDesk";
import GovtBody from "./pages/GovtBody";
import PresidentDesk from "./pages/PresidentDesk";
// import Overview from "./pages/Overview";
import CoursesOffered from "./pages/CoursesOffered";
import Eligibility from "./pages/Eligibility";
import Faculties from "./pages/Faculties";
import Department from "./pages/Department";
import Classroom from "./pages/Classroom";
import ProspectusStructure from "./pages/ProspectusStructure";
import Library from "./pages/Library";
import Facilities from "./pages/Facilities";
import Laboratory from "./pages/Laboratory";
import Syllabus from "./pages/Syllabus";
import Gallery from "./pages/Gallery";
import AntiragingCommittee from "./pages/AntiragingCommittee";
import ScStCell from "./pages/ScStCell";
import InternalComplaint from "./pages/InternalComplaint";
import TrainingPlacement from "./pages/TrainingPlacement";
import GuidanceCell from "./pages/GuidanceCell";
import GenderSensitization from "./pages/GenderSensitization";
import OurVisionMission from "./pages/OurVisionMission";
import SeminarHall from "./pages/SeminarHall";
import WifiCampus from "./pages/WifiCampus";
import Calendar from "./pages/Calendar";
import HolidayList from "./pages/HolidayList";
import StudentData from "./pages/StudentData";
import PrincipalIncharge from "./pages/PrincipalIncharge";
import Secretary from "./pages/Secretary";
import ApprovalOfPCI from "./pages/ApprovalOfPCI";
import DPharamConstantOfAffiliation from "./pages/DPharamConstantOfAffiliation";
import ISOCertificate from "./pages/ISOCertificate";
import UdyamCertificate from "./pages/UdyamCertificate";
import WBSCTVESD from "./pages/WBSCTVESD";
import AISHA from "./pages/AISHA";
import BOSSEAuthorization from "./pages/BOSSEAuthorization";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="governing-body" element={<GovtBody />} />
          {/* <Route path="overview" element={<Overview />} /> */}
          <Route path="vision-mission" element={<OurVisionMission />} />
          <Route path="principal-desk" element={<PrincipalDesk />} />
          <Route path="principal-incharge" element={<PrincipalIncharge />} />
          <Route path="president-desk" element={<PresidentDesk />} />
          <Route path="secretary-desk" element={<Secretary />} />
          <Route
            path="prospectus-structure"
            element={<ProspectusStructure />}
          />
          <Route path="courses-offered" element={<CoursesOffered />} />
          <Route path="eligibility" element={<Eligibility />} />
          <Route path="faculties" element={<Faculties />} />
          <Route path="department" element={<Department />} />
          <Route path="syllabus" element={<Syllabus />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="holiday-list" element={<HolidayList />} />
          <Route path="student-data" element={<StudentData />} />
          <Route path="classroom" element={<Classroom />} />
          <Route path="library" element={<Library />} />
          <Route path="facilities" element={<Facilities />} />
          <Route path="laboratory" element={<Laboratory />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="seminar-hall" element={<SeminarHall />} />
          <Route path="wifi-campus" element={<WifiCampus />} />
          <Route
            path="antiaging-committee-and-squad"
            element={<AntiragingCommittee />}
          />
          <Route
            path="gender-sensitization-cell"
            element={<GenderSensitization />}
          />
          <Route path="sc-st-cell" element={<ScStCell />} />
          <Route path="guidance-cell" element={<GuidanceCell />} />
          <Route path="internal-complaint" element={<InternalComplaint />} />
          <Route path="training-placement" element={<TrainingPlacement />} />
          <Route path="approval-of-pci" element={<ApprovalOfPCI />} />
          <Route
            path="d-pharam-constant-of-affiliation"
            element={<DPharamConstantOfAffiliation />}
          />
          <Route path="iso-certificate" element={<ISOCertificate />} />
          <Route path="udyam-certificate" element={<UdyamCertificate />} />
          <Route path="wbsct-&-ve-&-sd" element={<WBSCTVESD />} />
          <Route path="aishe" element={<AISHA />} />
          <Route path="contact-us" element={<Contact />} />
          <Route
            path="BOSSE-accredition-certificate"
            element={<BOSSEAuthorization />}
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;

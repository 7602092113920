import React from "react";
import { Link } from "react-router-dom";
import { SeminarHallImg } from "../images";

const SeminarHall = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Seminar Hall</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Seminar Hall</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="features-area Principal_sec">
        <div className="container">
          <div className="row mt-4 justify-content-center">
            <div className="col-lg-6 col-md-12">
              <img src={SeminarHallImg} alt="" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeminarHall;

export const endpoints = {
  makeContactPost: "https://cms.yeasitech.com/api/bep-contact-uses",
  newsAndNoticeGet: "https://cms.yeasitech.com/api/bep-notices?populate=*",
  gallerySectionGet: "https://cms.yeasitech.com/api/bep-gallery?populate=*",
  emailContact: "https://api.yeasitech.com/bengal-eop-send-mail",
  getFaculty: "https://cms.yeasitech.com/api/bep-faculties?populate=*",
  getHoliday: `https://cms.yeasitech.com/api/bep-certificates?filters[name][$eq]=Holiday&populate[file][fields]=url&populate[logo][fields]=url&sort[year]=desc&&pagination[limit]=3`,
  getGraphs: "https://cms.yeasitech.com/api/bep-students-graphs?populate=*",
  getStudents: "https://cms.yeasitech.com/api/bep-students-datas?populate=*",
  // getPCICertificate:
  //   "https://cms.yeasitech.com/api/bep-certificates?filters[name][$eq]=Approval of PCI&populate[file][fields]=url",
  getPCICertificate:
    "https://cms.yeasitech.com/api/bep-certificates?filters[name][$eq]=Approval of PCI&populate[file][fields]=url&populate[logo][fields]=url&sort[year]=desc&&pagination[limit]=3",
  getDPharamCertificate:
    "https://cms.yeasitech.com/api/bep-certificates?filters[name][$eq]=D.Pharam%20Constant%20of%20affiliation&populate[file][fields]=url&populate[logo][fields]=url&sort[year]=desc&pagination[limit]=3",
  getISOCertificate:
    "https://cms.yeasitech.com/api/bep-certificates?filters[name][$eq]=ISO Certificate&populate[file][fields]=url",
  getUdyamCertificate:
    "https://cms.yeasitech.com/api/bep-certificates?filters[name][$eq]=Udyam Certificate&populate[file][fields]=url",
  getWBSCTVESDCertificate:
    "https://cms.yeasitech.com/api/bep-certificates?filters[name][$eq]=WBSCT%26VE%26SD&populate[file][fields]=url&populate[logo][fields]=url&sort[year]=desc&&pagination[limit]=1",
  getAISHACertificate:
    "https://cms.yeasitech.com/api/bep-certificates?filters[name][$eq]=AISHE&populate[file][fields]=url&populate[logo][fields]=url",
  getModal:
    "https://cms.yeasitech.com/api/bep-pop-ups?filters[active][$eq]=true&populate[image][fields]=url",
};

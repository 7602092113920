import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export default function PdfLoader({ pdfUrl }) {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (toolbarSlot) => (
      <Toolbar>
        {(props) => {
          const {
            Zoom,
            ZoomIn,
            ZoomOut,
            SwitchTheme,
            // Remove Download button
          } = props;
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <ZoomOut />
              <Zoom />
              <ZoomIn />
              <SwitchTheme />
            </div>
          );
        }}
      </Toolbar>
    ),
  });

  return (
    <div style={{ height: "1000px" }}>
      <Viewer
        fileUrl={pdfUrl}
        plugins={[defaultLayoutPluginInstance]}
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
        }}
      />
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

const GovtBody = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Governing Body</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Governing Body</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="governing-body">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              {/* <div className="governing-img">
                <img src={DirectorMsg} alt="" />
              </div> */}
              <div className="governing-main">
                <h4>Mr. Guruprosad Mukherjee</h4>
                <p>President</p>
              </div>
            </div>
            <div className="col-sm-3">
              {/* <div className="governing-img">
                <img src={SecretaryPic} alt="" />
              </div> */}
              <div className="governing-main">
                <h4>Mr. Soumyajit Mukherjee</h4>
                <p>Secretary</p>
              </div>
            </div>
            {/* <div className="col-sm-3">
              <div className="governing-img">
                <img src={PujaFaculties} alt="" />
              </div>
              <div className="governing-main">
                <h4>Mrs Puja Adhikary</h4>
                <p>Principal</p>
              </div>
            </div> */}
            <div className="col-sm-3">
              {/* <div className="governing-img">
                <img src={AnneshaFaculties} alt="" />
              </div> */}
              <div className="governing-main">
                <h4>Mrs Annesha A. Acharyya</h4>
                <p>Principal Incharge</p>
              </div>
            </div>
            <div className="col-sm-3">
              {/* <div className="governing-img">
                <img src={MemberOnePic} alt="" />
              </div> */}
              <div className="governing-main">
                <h4>Mr. Achintya Ghosh</h4>
                <p>Member</p>
              </div>
            </div>
            <div className="col-sm-3">
              {/* <div className="governing-img">
                <img src={MemberTwoPic} alt="" />
              </div> */}
              <div className="governing-main">
                <h4>Mr. Sanjoy Roychowdhury</h4>
                <p>Member</p>
              </div>
            </div>
            <div className="col-sm-3">
              {/* <div className="governing-img">
                <img src={MemberThreePic} alt="" />
              </div> */}
              <div className="governing-main">
                <h4>Mr. Sandipan Ghatak</h4>
                <p>Member</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GovtBody;

import React from "react";
import { Link } from "react-router-dom";
import { PujaFaculties } from "../images";

const PrincipalDesk = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Principal Desk</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Principal Desk</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="features-area Principal_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <div className="features-img text-center">
                <img
                  src={PujaFaculties}
                  alt=""
                  style={{ height: "400px", objectFit: "contain" }}
                />
                <h4>Mrs Puja Adhikary</h4>
                <p>(Principal)</p>
              </div>
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="features-content">
                <p>
                  <b>Dear Students, Faculty, and Colleagues,</b> <br />
                  It is my privilege to welcome you to Bengal Education of
                  Pharmacy College, an institution dedicated to excellence in
                  pharmaceutical education. Our commitment to providing a
                  comprehensive, forward-thinking curriculum ensures that our
                  students are well-prepared to face the challenges and demands
                  of the healthcare industry.
                </p>
                <p>
                  At Bengal Education of Pharmacy College, we believe in
                  blending academic rigor with practical experience. Our
                  students benefit from an environment that encourages critical
                  thinking, fosters innovation, and promotes continuous
                  learning. With a dedicated and experienced faculty,
                  well-equipped laboratories, and strong industry partnerships,
                  we strive to provide our students with a holistic education
                  that is in line with global standards.
                </p>
                <p>
                  Our vision is to develop pharmacy professionals who are not
                  only proficient in their field but also driven by values of
                  compassion, integrity, and social responsibility. We aim to
                  empower our students to make meaningful contributions to the
                  health and well-being of society, ensuring that they are ready
                  to be leaders in the ever-evolving pharmaceutical world.
                </p>
                <p>
                  As we embark on another academic year, I encourage all of you
                  to embrace the opportunities ahead, make the most of your
                  learning experiences, and strive for excellence in everything
                  you do. Let us work together to achieve great success and make
                  a positive impact on the world.
                </p>
                <p>Thank you for being a part of this inspiring journey.</p>
              </div>
            </div>
          </div>
          {/* <div className="row mt-4">
            <div className="col-lg-8 col-md-12">
              <div className="features-content">
                <p>
                  <b>Dear students,</b>
                  <br /> IPharmacy is not just a career—it is a calling, a
                  commitment to serve, innovate, and make a difference in the
                  lives of countless individuals. The field of pharmacy has
                  evolved tremendously over the years. This transformation
                  reflects the critical role that pharmacy professionals play in
                  shaping the future of healthcare. As pharmacy students, you
                  are entrusted with the responsibility of acquiring scientific
                  knowledge and practical skills that will serve society in the
                  most meaningful ways.
                </p>
                <p>
                  I encourage you to remain curious, ask questions, and seek
                  answers beyond textbooks. Knowledge is a dynamic entity, and
                  staying ahead requires persistent effort and dedication.
                </p>
                <p>
                  There will be times when the journey feels challenging—exams
                  may be tough, concepts may seem complex, and practical
                  sessions may feel overwhelming. But remember, every struggle
                  is a step towards greatness. The effort you put in today will
                  shape your future and define your professional journey.
                </p>
                <p>
                  As we move forward together, let us uphold the values of
                  knowledge, integrity, and service. The future is bright, and
                  the possibilities are endless. With passion, perseverance, and
                  dedication, we can achieve great things.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="features-img text-center">
                <img src={AnneshaFullImg} alt="" />
                <h4>Mrs Annesha A. Acharyya</h4>
                <p>(Principal Incharge)</p>
              </div>
            </div>
            <div className="col-12 features-content">
              <p>
                Let us continue to strive for excellence, embrace challenges,
                and work towards making a meaningful impact in the world of
                pharmacy and healthcare.
              </p>
              <p>
                Wishing you all success, happiness, and fulfillment in your
                academic and professional endeavors.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PrincipalDesk;

import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { FaGraduationCap } from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";
import { getApiCall } from "../Service/Home/index";
import { endpoints } from "../Config/endpoints";
import AutoPopupModal from "../components/AutoPopupModal";
import Slider from "../components/Slider/Slider";

const Home = () => {
  const [allNoticeStock, setAllNoticeStock] = useState([]);
  const [holdGalleryData, setHoldGalleryData] = useState([]);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([]);

  const getModalData = async () => {
    const res = await getApiCall(endpoints.getModal);
    setModalData(res?.data?.data);
    const timer = setTimeout(() => {
      setShow(res?.data?.data[0].active);
    }, 5000);
    return () => clearTimeout(timer);
  };

  const getNotice = async () => {
    const res = await getApiCall(endpoints.newsAndNoticeGet);
    setAllNoticeStock(res?.data?.data);
  };

  const getGallery = async () => {
    const res = await getApiCall(endpoints.gallerySectionGet);
    setHoldGalleryData(res?.data?.data?.files);
  };
  // useEffect(() => {
  //   const audio = new Audio("notification.mp3");
  //   audio.addEventListener("canplaythrough", () => {
  //     setTimeout(() => {
  //       audio.play().catch((err) => console.error("Playback error:", err));
  //     }, 5000);
  //   });

  //   return () => {
  //     audio.pause();
  //     audio.currentTime = 0;
  //   };
  // }, []);

  useEffect(() => {
    getNotice();
    getGallery();
    getModalData();
  }, []);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  return (
    <>
      <Slider />
      <div className="mandatory">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-2">
              <p>Latest News:</p>
            </div>
            <div className="col-sm-9 col-md-9 col-lg-10">
              <div className="marquee-news" width="100%" direction="left">
                {allNoticeStock && (
                  <p className="mb-0">
                    {allNoticeStock[allNoticeStock.length - 1]?.description}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about area */}
      <div className="about-area">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {/* <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <img src={AboutFirstImg} className="about_first" alt="" />
                </div>
                <div className="col-sm-6">
                  <img
                    src={AboutSecondImg}
                    className="about_second mb-3"
                    alt=""
                  />
                  <img src={AboutThirdImg} className="about_second" alt="" />
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="about-title">
                <h5>
                  <FaGraduationCap /> About Our College
                </h5>
                <h2>Welcome to Bengal Education of Pharmacy</h2>
                <p className="txt-clr">
                  Bengal Education of Pharmacy (BEOP) – <br /> Empowering the
                  Future of Healthcare Bengal Education of Pharmacy (BEOP) is a
                  pioneering pharmacy institution established in 2023, with the
                  vision of shaping the future of healthcare and pharmaceutical
                  education in Bengal. As one of the newest and most promising
                  pharmacy colleges in the region, BEOP is committed to
                  providing high-quality education, cutting-edge research
                  opportunities, and a holistic approach to training the next
                  generation of healthcare professionals.
                </p>
                <p className="txt-clr">
                  Accreditations and Approvals <br />
                  The college is approved by the Pharmacy Council of India
                  (PCI), which ensures that it adheres to the highest academic
                  and professional standards set for pharmacy education across
                  India. Additionally, BEOP is recognized by the West Bengal
                  State Council of Technical & Vocational Education and Skill
                  Development, which further attests to the institution's
                  credibility in the state’s educational framework. These
                  approvals place BEOP on the map as a reliable and trusted
                  institution in the field of pharmaceutical studies.
                </p>
                <p className="txt-clr">
                  Academic Excellence and Programs <br /> At BEOP, the emphasis
                  is placed on delivering a strong foundation in pharmaceutical
                  sciences, combined with practical training and research
                  exposure. The institution offers undergraduate and diploma
                  programs designed to equip students with the skills,
                  knowledge, and expertise required to excel in the
                  pharmaceutical industry, healthcare sectors, research
                  organizations, and academia. The curriculum is crafted in
                  alignment with the evolving needs of the pharmaceutical
                  industry, ensuring that students receive up-to-date training
                  in various fields, including drug development, clinical
                  pharmacy, pharmacology, pharmaceutical marketing, quality
                  control, and pharmaceutical technology. The college fosters a
                  learning environment where students are not only taught
                  theoretical knowledge but also encouraged to gain hands-on
                  experience through internships, workshops, and industry
                  exposure.
                </p>
                <p className="txt-clr">
                  Infrastructure and Facilities <br /> BEOP boasts
                  state-of-the-art infrastructure, providing students with
                  access to well-equipped laboratories, modern classrooms, and a
                  dedicated research space. The college strives to create an
                  environment that fosters creativity, innovation, and critical
                  thinking. With up-to-date library resources, computer labs,
                  and digital tools, BEOP ensures that students are ready to
                  face the challenges of the modern healthcare industry.
                </p>
                <p className="txt-clr">
                  Research and Innovation <br /> Research is at the core of
                  BEOP’s mission to advance the pharmaceutical sciences. The
                  college encourages students and faculty members to actively
                  engage in research initiatives aimed at solving real-world
                  health issues. BEOP strives to create an environment where
                  students can contribute to advancements in drug development,
                  clinical research, and healthcare delivery. The institution
                  also nurtures collaborations with the pharmaceutical industry,
                  research institutes, and healthcare organizations to promote
                  innovation in drug therapy and management.
                </p>
                <p className="txt-clr">
                  Skilled and Experienced Faculty <br /> BEOP’s faculty consists
                  of highly qualified and experienced professionals who bring
                  diverse knowledge and expertise from various domains within
                  pharmacy and healthcare. The faculty members are dedicated to
                  providing students with personalized guidance, fostering
                  intellectual growth, and helping students prepare for
                  successful careers in the pharmaceutical field. With a
                  student-centric approach to teaching, BEOP ensures that each
                  learner receives the support they need to achieve their
                  educational and professional goals.
                </p>
                <p className="txt-clr">
                  Industry Linkages and Career Development <br /> Understanding
                  the importance of industry-relevant skills and placement
                  opportunities, BEOP maintains strong ties with leading
                  pharmaceutical companies, hospitals, and research
                  organizations. These partnerships offer students valuable
                  exposure through internships, industrial visits, and
                  placements in prestigious institutions. BEOP’s Career
                  Development Cell works tirelessly to connect students with job
                  opportunities, conduct mock interviews, and offer career
                  counseling, ensuring that graduates are well-equipped to enter
                  the workforce with confidence.
                </p>
                <p className="txt-clr">
                  Student-Centric Approach and Holistic Development <br />
                  BEOP places great emphasis on the overall development of its
                  students. The institution offers various extracurricular
                  activities, including cultural events, sports, seminars, and
                  workshops, designed to enhance leadership, communication, and
                  teamwork skills. BEOP encourages its students to participate
                  in various competitions and conferences to hone their critical
                  thinking and problem-solving abilities. The college believes
                  in nurturing well-rounded professionals who not only excel
                  academically but also contribute meaningfully to society. As
                  part of its community outreach initiatives, BEOP engages
                  students in social service programs, spreading awareness about
                  public health, drug safety, and sustainable healthcare
                  practices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* features area */}
      <div className="features-gallery">
        <div className="container">
          <div className="row">
            <OwlCarousel className="slider-items owl-carousel" {...options}>
              {holdGalleryData &&
                holdGalleryData?.map((item, i) => {
                  return (
                    <div key={i} className="item">
                      <div className="gallery-main">
                        <img src={item?.url} alt="" />
                      </div>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* contact area */}
      <div className="contact-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="notice">
                <div className="notice_title">
                  <h3>News And Notice</h3>
                </div>
                <div className="notice_list">
                  <ul>
                    {allNoticeStock &&
                      allNoticeStock?.map((item, i) => (
                        <li key={i}>
                          {item?.files?.map((ele, j) => (
                            <Link key={j} to={ele?.url} target="_blank">
                              {item?.description}
                            </Link>
                          ))}
                          {item?.isNew === true && (
                            <img
                              src="https://www.eiti.org.in/assets/froantend/images/new.gif"
                              alt=""
                            />
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="about-title">
                <h2 className="text-center">Facilities</h2>
              </div>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-wifi"></i>
                    <span>WIFI CAMPUS</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-leaf"></i>
                    <span>GREEN CAMPUS</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-graduation-cap"></i>
                    <span>SCHOLARSHIP AVAILABLE</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-briefcase"></i>
                    <span>100% PLACEMENT</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-credit-card"></i>
                    <span>STUDENT CREDIT CARD</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-bolt"></i>
                    <span>24X7 POWER SUPPLY</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-laptop"></i>
                    <span>INDIVIDUAL PORTAL/APPS GATEWAY</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-video"></i>
                    <span>CCTV SURVEILLANCE</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-flask"></i>
                    <span>CUTTING-EDGE LAB & FACILITIES</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-book-open"></i>
                    <span>NSP SCHOLARSHIP AVAILABLE</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="facility-box">
                    <i className="fas fa-chalkboard-teacher"></i>
                    <span>AC CLASSROOM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <AutoPopupModal show={show} setShow={setShow} modalData={modalData} />
      )}
    </>
  );
};

export default Home;

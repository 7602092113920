import React from "react";
import { Link } from "react-router-dom";

const OurVisionMission = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Vision & Mission</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Vision & Mission</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="vision-con">
                <ul className="mt-4">
                  <div className="content">
                    <div className="text">
                      <h3>Our Vision</h3>
                      <p>
                        Bengal Education of Pharmacy College aims to be a center
                        of excellence in pharmaceutical education, research, and
                        healthcare services, dedicated to producing highly
                        skilled, ethical, and innovative pharmacists who
                        contribute to the well-being of society and advance the
                        pharmaceutical industry through knowledge, technology,
                        and professional integrity.
                      </p>
                      <p>
                        Bengal Education of Pharmacy College is dedicated to
                        produce competent, ethical, and innovative pharmacists
                        who contribute to the advancement of the healthcare
                        system. Our college aims to foster excellence in patient
                        care and pharmaceutical sciences by integrating
                        cutting-edge technology and facilities. Through industry
                        collaboration and global standards, it aspires to
                        prepare students for national and international
                        opportunities while ensuring the highest level of
                        professional integrity. With a commitment to community
                        service and lifelong learning, the institution envisions
                        shaping future pharmacists to make a meaningful impact
                        on society and public health.
                      </p>
                    </div>
                  </div>
                  <div className="content mt-4">
                    <div className="text">
                      <h3>Our Mission</h3>
                      <ol>
                        <li>
                          To provide high-quality pharmaceutical education with
                          a strong foundation in theoretical and practical
                          knowledge.
                        </li>
                        <li>
                          To promote research in patient care, and
                          pharmaceutical sciences.
                        </li>
                        <li>
                          To instill ethics, professionalism, and responsibility
                          in students for patient-centered care.
                        </li>
                        <li>
                          To create awareness and contribute to public health
                          through outreach programs and pharmaceutical
                          advancements.
                        </li>
                        <li>
                          To Develop a Symbiotic Relationship between the
                          College, Faculty and the Community for Mutual
                          Betterment with a Global Perspective{" "}
                        </li>
                        <li>
                          Train competent pharmacists to meet the demands of the
                          healthcare and pharmaceutical industries.
                        </li>
                        <li>
                          Educate students on safe and effective medication use
                          for better healthcare outcomes.
                        </li>
                        <li>
                          Encourage continuous education and professional
                          development in the pharmacy field.
                        </li>
                        <li>
                          Organize awareness programs and community services to
                          improve healthcare accessibility.
                        </li>
                        <li>
                          Encourage students to establish pharmacies and
                          pharmaceutical startups to contribute to the industry.
                        </li>
                      </ol>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurVisionMission;
